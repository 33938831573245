import arrowH from "../../../images/icons/icon-arrow-h.png";
import arrowHRed from "../../../images/icons/icon-arrow-h-red.png";
import React,{ PureComponent } from "react";
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

class Title extends PureComponent  {
    render(){
        const { title,link,rightLabel,match } = this.props
        return (
            <div className="title">
                <h2 className="title__caption">{title}</h2>
                <Link className="title__link" to={link ? `/${match.params.locale + link}` : `#`}>{rightLabel}
                    <img className="arrow-basic"
                         src={arrowH}
                         alt=""/>
                    <img className="arrow-hover"
                         src={arrowHRed}
                         alt=""/>
                </Link>
            </div>
        )
    }

}

export default withRouter(Title)

